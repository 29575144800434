@mixin tabs-theme($theme) {

  .content-tabs {
    &.content-tabs--accordion {
      .content-tabs__link {
        &.current-selection {
          border-left-color: map-get($theme, 'tabs-primary');
          &+ .content-tabs__content-item {
            border-left-color: map-get($theme, 'tabs-primary');
          }
          &::after {
            color: map-get($theme, 'tabs-primary');
          }
        }
      }
      .content-tabs__content-item {
        border-left-color: map-get($theme, 'tabs-primary');
      }
      .content-tabs__list-item {
        &:active,
        &:focus,
        &:hover {
          .content-tabs__link {
            border-left-color: map-get($theme, 'tabs-primary');
          }
        }
        .content-tabs__link {
          &:active,
          &:focus,
          &:hover {
            border-left-color: map-get($theme, 'tabs-primary');
          }
        }
      }
    }
    &:not(.content-tabs--accordion) {
      .content-tabs__link {
        &[aria-selected="true"] {
          &::before {
            background: linear-gradient(89deg, map-get($theme, 'tabs-primary') 0%, map-get($theme, 'tabs-secondary'));
          }
          &::after {
            border-top-color: map-get($theme, 'tabs-primary');
          }
        }
        &:active,
        &:focus,
        &:hover {
          &::before {
            background: linear-gradient(89deg, map-get($theme, 'tabs-primary') 0%, map-get($theme, 'tabs-secondary'));
          }
        }
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .content-tabs {
      &.content-tabs--accordion:not(.content-tabs--vertical),
      &:not(.content-tabs--accordion) {
        .content-tabs__link {
          &[aria-selected="true"] {
            &::before {
              background: linear-gradient(89deg, map-get($theme, 'tabs-primary') 0%, map-get($theme, 'tabs-secondary'));
            }
            &::after {
              border-top-color: map-get($theme, 'tabs-primary');
            }
          }
          &:focus,
          &:hover {
            &::before {
              background: linear-gradient(89deg, map-get($theme, 'tabs-primary') 0%, map-get($theme, 'tabs-secondary'));
            }
          }
        }
      }
    }
  }

}