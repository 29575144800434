@mixin privacy-popup-theme($theme) {

  .privacy-popup {
    button.button--transparent {
      &:focus,
      &:hover {
        color: map-get($theme, 'privacy-popup-primary');
      }
    }
  }

}