@mixin main-menu-theme($theme) {

  .main-menu__lvl1 {
    > li:hover,
    > li:focus {
      > a {
        border-bottom: 4px solid map-get($theme, 'menu-underline');
      }
    }
  }
}