@mixin button-theme($theme) {
  .main form input.sq-form-submit,
  input.button,
  a.button,
  button.button {
    background-image: linear-gradient(83deg, map-get($theme, 'button-bg2') 3%, map-get($theme, 'button-bg1') 225%);
  
    &:hover,
    &:focus {
      background-color: darken(map-get($theme, 'button-bg1'), 10%);
    }
  }
  
  input.button.button--secondary,
  a.button.button--secondary,
  button.button.button--secondary {
    background-color: map-get($theme, 'button-secondary');

    &:hover,
    &:focus {
      background-color: lighten(map-get($theme, 'button-secondary'), 25%);
    }
  }

  input.button.button--transparent,
  button.button.button--transparent, 
  a.button.button--transparent {
    &:focus,
    &:hover {
      &::after {
        color: map-get($theme, 'button-bg1');
      }
    }
  }

}

@mixin link-theme($theme) {
  a {
    &:hover,
    &:focus {
      color: map-get($theme, 'link-primary');
    }
  }
}

@mixin ordered-list-theme($theme) {
  
  ul > li {
    &::before {
      background: map-get($theme, 'ordered-list-primary');
    }

    ul > li::before {
      border: solid 1.5px map-get($theme, 'ordered-list-primary');
    }
  }
}

@mixin blockquote-theme($theme) {

  blockquote::before {
    background: map-get($theme, 'blockquote-border');
  }
}