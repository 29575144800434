@mixin navigation-cards-text-theme($theme) {

  .navigation-cards-text__wrapper {
    .navigation-cards-text {
      .navigation-cards-text__item {
        a {
          &:hover,
          &:focus {
            &:after {
              color: map-get($theme, 'nav-cards-text-primary');
            }
          }
          &.external {
            &:hover,
            &:focus {
              &:after {
                color: map-get($theme, 'nav-cards-text-primary');
              }
            }
          }
        }
      }
    }
  }

}