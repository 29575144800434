@mixin header-theme($theme) {

  .header-searchbar__close,
  .search-toggle {
      background-color: map-get($theme, 'search-btn');

      &:focus,
      &:hover {
        background-color: lighten(map-get($theme, 'search-btn'), 25%);
      }
  }

  .header-searchbar__btn {
    background-color: map-get($theme, 'searchbar-btn');

    &:focus,
    &:hover {
      background-color: darken(map-get($theme, 'searchbar-btn'), 10%);
    }
  }
}
