@mixin file-listing-theme($theme) {
  
  .file-listing {
    &__file {
      a {
        &:focus,
        &:hover {
          &::before {
            background: map-get($theme, 'file-listing-primary');
          }
        }
      }
    }
  }

}