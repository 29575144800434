@mixin search-global-theme($theme) {

  .search-global {
    .tabs {
      .filter-navbar-collapse {
        .filter-nav-tabs {
          .filter-nav-item {
            a {
            &.active {
                @media screen and (min-width: $tablet) {
                  border-bottom-color: map-get($theme, 'search-global-primary');
                  &:after {
                    border-top-color: map-get($theme, 'search-global-primary');
                  }
                }
              }
              &:hover {
                @media screen and (min-width: $tablet) {
                  border-bottom-color: map-get($theme, 'search-global-primary');
                  &:after {
                    border-top-color: map-get($theme, 'search-global-primary');
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .pagination{
    .page-item{
      .page-link{
        &:active,
        &:focus,
        &:hover{
          color: map-get($theme, 'search-global-primary');
          .page-icon__prev::before,
          .page-icon__next::before{
            color: map-get($theme, 'search-global-primary');
          }
        }
      }
      &.active a{
        color: map-get($theme, 'search-global-primary');
        border-bottom-color: map-get($theme, 'search-global-primary');
      }
    }
  }

  @media screen and (min-width: $desktop){
    .search-results{
      .dropdown{
        &-toggle{
          &:active,
          &:focus,
          &:hover{
            border-color: map-get($theme, 'search-global-primary');
          }
        }
      }
    }
  }

}