@mixin personalisation-ctrl-theme($theme) {
  .personalisation-ctrl__international button[data-js-personalisation-btn-active],
  .personalisation-ctrl__international button:focus,
  .personalisation-ctrl__international button:hover,
  .personalisation-ctrl__interests button[data-js-personalisation-btn-active], 
  .personalisation-ctrl__interests button:focus, 
  .personalisation-ctrl__interests button:hover {
    background: map-get($theme, 'personalisation-ctrl-btn');
  }
  
  button.personalisation-ctrl__update {
    background: map-get($theme, 'personalisation-ctrl-update');

    &:hover,
    &:focus {
      background-color: darken(map-get($theme, 'personalisation-ctrl-update'), 10%);
    }
  }
}