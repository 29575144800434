@mixin image-cards-theme($theme) {

  .image-cards__card-link {
    border-color: map-get($theme, 'image-cards-primary');
  }

  @media screen and (min-width: $desktop) {
    .image-cards {
      .glide__arrow {
        &:hover,
        &:focus {
          background-color: map-get($theme, 'image-cards-primary');
        }
      }
    }
  }

}