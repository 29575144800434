@mixin footer-theme($theme) {

  .footer {
      background-color: map-get($theme, 'footer-bg');

    a {
      color: #fff;

      &:active,
      &:focus,
      &:hover {
        color: #fff;
      }
    }
  }
}
