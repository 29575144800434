@mixin form-elements-theme($theme) {

  .main form {

    .sq-form-error {
      color: map-get($theme, 'form-elements-primary');
    }

    .sq-form-question-error textarea, 
    .sq-form-question-error input[type="text"] {
      border: solid 1px map-get($theme, 'form-elements-primary');
    }

    select:hover, 
    select:active, 
    select:focus, 
    textarea:hover, 
    textarea:active, 
    textarea:focus, 
    input[type=textarea]:hover, 
    input[type=textarea]:active, 
    input[type=textarea]:focus,
    input[type=tel]:hover, 
    input[type=tel]:active, 
    input[type=tel]:focus, 
    input[type=text]:hover, 
    input[type=text]:active, 
    input[type=text]:focus {
      border: solid 1px map-get($theme, 'form-elements-secondary');
    }

    input.sq-form-reset:focus,
    input.sq-form-reset:hover {
      background-color: darken(map-get($theme, 'form-elements-primary'), 10%);
    }

    input[type=text].custom-date {
      background-image: map-get($theme, 'form-elements-icon');
    }
  }

}