@mixin accordion-theme($theme) {
  
  .accordion {
    .accordion-panel-content,
    input:checked + .accordion-panel-label,
    input:checked ~ .accordion-panel-content,
    .accordion-panel input:hover + .accordion-panel-label,
    .accordion-panel input:active + .accordion-panel-label,
    .accordion-panel input:focus + .accordion-panel-label, 
    .accordion-panel:active .accordion-panel-content, 
    .accordion-panel:active .accordion-panel-label, 
    .accordion-panel:focus .accordion-panel-content, 
    .accordion-panel:focus .accordion-panel-label, 
    .accordion-panel:hover .accordion-panel-content, 
    .accordion-panel:hover .accordion-panel-label {
      border-left-color: map-get($theme, 'accordion-primary');
    }
    input:checked {
      + .accordion-panel-label {
        &::after {
          color: map-get($theme, 'accordion-primary');
        }
      }
    }
  }

}
