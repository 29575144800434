@mixin card-listing-theme($theme) {
  
  .card_listing {
    &__tile {
      &--book-now {
        color: map-get($theme, 'card-listing-primary');
      }
    }
  }

}