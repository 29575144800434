
@mixin sidebar-menu-theme($theme) {

  .sidebar-menu {
    li.active > a {
      background: map-get($theme, 'sidebar-menu-secondary');
    }

    li.active > button, 
    &__lvl2 a, 
    &__lvl2 button {
      border-left: 4px solid map-get($theme, 'sidebar-menu-primary');
    }
  }

}
