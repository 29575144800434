@mixin on-this-page-theme($theme) {
  
  .on-this-page__inner {
    &:before {
      background: map-get($theme, 'on-this-page-color');
    }

    a:hover,
    a:active {
      color: map-get($theme, 'on-this-page-color');
    }

  }
}